import React, { useEffect, useState, useRef, useCallback } from "react";
import Regions from "../Regions/Regions";
import MiniMap from "../MiniMap/MiniMap";
import Info from "../Info/Info";
import Spin from "../Spin/Spin";
import Shtor from "../Shtor/Shtor";
// import Clouds from "../Clouds/Clouds";

function App({ logOut, lang, setLang }) {
  const pc =
    !/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent
    );
  const app = useRef();
  const [mMouse, setMmouse] = useState({});
  const [chek, setChek] = useState(false);
  const [mMap, setmMap] = useState({ x: 10, y: 1000 });
  const [mR, setMr] = useState({ x: 0, y: 0 });
  const [lLabel, setlLabel] = useState({ x: 0, y: 0 });
  const [appSize, setAppSize] = useState({ w: 0, h: 0 });
  const [miniMap, setMiniMap] = useState(null);
  const [cuntain, setCuntain] = useState(false);
  const [mapLoad, setMapLoad] = useState(false);
  const [info, setInfo] = useState(false);

  useEffect(() => {
    setMapLoad(false);
  }, [lang]);

  // useEffect(() => {
  //   if (mapLoad) {
  //     document.getElementById("birds").style.display = "block";
  //   }
  //   return () => {
  //     document.getElementById("birds").style.display = "none";
  //   };
  // }, [mapLoad]);

  const handleMiniMap = useCallback((e) => {
    let x =
      (e.clientX - (app.current.clientWidth - e.target.clientWidth) + 10) * 10 -
      app.current.clientWidth / 2;
    let y =
      (e.clientY - (app.current.clientHeight - e.target.clientHeight) + 10) *
      10 -
      app.current.clientHeight / 2;

    app.current.scrollTo({
      top: y,
      left: x,
      behavior: "smooth",
    });
  }, []);

  const butHandler = useCallback((id) => {
    setCuntain(id);
  }, []);

  const closeHandler = useCallback(() => {
    setInfo(false);
    setCuntain(false);
  }, []);
  const infoHandl = useCallback(() => {
    setInfo((a) => !a);
  }, []);

  useEffect(() => {
    setMiniMap({
      mapW: app.current.scrollWidth / 10,
      mapH: app.current.scrollHeight / 10,
      screenW: app.current.clientWidth / 10,
      screenH: app.current.clientHeight / 10,
      screenTop: mMap.y / 10 > 0 ? mMap.y / 10 : 0,
      screenLeft: mMap.x / 10 > 0 ? mMap.x / 10 : 0,
    });

    if (chek && pc) {
      app.current.scroll(mMap.x, mMap.y);
    }
  }, [mMap, chek, pc]);

  useEffect(() => {
    if (chek) {
      let x = mR.x + lLabel.x - mMouse.x;
      let y = mR.y + lLabel.y - mMouse.y;

      if (x > appSize.w) {
        setlLabel((l) => {
          return { ...l, x: l.x - (mR.x + lLabel.x - mMouse.x - appSize.w) };
        });
        x = appSize.w;
      }
      if (y > appSize.h) {
        setlLabel((l) => {
          return { ...l, y: l.y - (mR.y + lLabel.y - mMouse.y - appSize.h) };
        });
        y = appSize.h;
      }

      if (x < 0) {
        setlLabel((l) => {
          return { ...l, x: l.x - (mR.x + lLabel.x - mMouse.x) };
        });
        x = 0;
      }
      if (y < 0) {
        setlLabel((l) => {
          return { ...l, y: l.y - (mR.y + lLabel.y - mMouse.y) };
        });
        y = 0;
      }
      setmMap({ x, y });
    }
  }, [mMouse, chek, mR, appSize, lLabel]);

  console.log("Loading APP");

  return (
    <div
      className="wrapper"
      id="wrapper"
      onMouseOut={(e) => {
        if (e.relatedTarget && e.relatedTarget.nodeName === "HTML") {
          setChek(false);
        }
      }}
    >
      {mapLoad ? (
        <Info
          handle={infoHandl}
          close={closeHandler}
          active={info}
          lang={lang}
        />
      ) : null}
      {/* <div className="lang_box">
        <img
          src="/img/en.svg"
          alt=""
          className={lang === "en" ? "active" : ""}
          onClick={() => {
            setLang("en");
          }}
        />
        <img
          src="/img/ru.svg"
          alt=""
          className={lang === "ru" ? "active" : ""}
          onClick={() => {
            setLang("ru");
          }}
        />
        <img src="img/out.jpg" alt="" onClick={logOut} />
      </div> */}
      {/* {!mapLoad && pc ? <Spin /> : null} */}
      <div
        className="App"
        ref={app}
        onScroll={(e) => {
          if (!chek) {
            setmMap({ x: e.target.scrollLeft, y: e.target.scrollTop });
          }
        }}
        onTouchMove={(e) => {
          if (chek) {
            setMmouse({
              x: e.touches[0].pageX - e.currentTarget.offsetLeft,
              y: e.touches[0].pageY - e.currentTarget.offsetTop,
            });
          }
        }}
        onTouchStart={(e) => {
          if (e.target.nodeName !== "BUTTON") {
            setMmouse({
              x:
                e.touches[e.touches.length - 1].pageX -
                e.currentTarget.offsetLeft,
              y:
                e.touches[e.touches.length - 1].pageY -
                e.currentTarget.offsetTop,
            });

            setAppSize({
              w: e.target.clientWidth - e.currentTarget.clientWidth,
              h: e.target.clientHeight - e.currentTarget.clientHeight,
            });
            setMr({ ...mMap });
            setChek(true);
            setlLabel({
              x:
                e.touches[e.touches.length - 1].pageX -
                e.currentTarget.offsetLeft,
              y:
                e.touches[e.touches.length - 1].pageY -
                e.currentTarget.offsetTop,
            });
          }
        }}
        onTouchEnd={(e) => {
          setChek(false);
        }}
        onMouseMove={(e) => {
          setMmouse({
            x: e.pageX - e.currentTarget.offsetLeft,
            y: e.pageY - e.currentTarget.offsetTop,
          });
        }}
        onMouseDown={(e) => {
          if (e.target.nodeName !== "BUTTON") {
            setAppSize({
              w: e.target.clientWidth - e.currentTarget.clientWidth,
              h: e.target.clientHeight - e.currentTarget.clientHeight,
            });

            setMr({ ...mMap });
            setChek(true);
            setlLabel(mMouse);
          }
        }}
        onMouseUp={(e) => {
          setChek(false);
        }}
        style={chek ? { cursor: "grabbing" } : { cursor: "grab" }}
      >
        <div className="map">
          <img
            className="img"
            src={`/img/map_${lang}.jpg`}
            onLoad={() => {
              app.current.scrollTo(app.current.scrollWidth, 0);
              setMapLoad(true);
              console.log(lang);
            }}
            onMouseMove={(e) => {
              e.preventDefault();
            }}
            alt=""
            style={mapLoad ? { opacity: 1 } : { opacity: 0 }}
          />
          {mapLoad ? (
            <Regions
              lang={lang}
              handler={butHandler}
              active={cuntain}
              close={closeHandler}
            />
          ) : null}
          {/* 
          <Clouds
            mMap={mMap}
            x={app.current ? app.current.scrollWidth : 1000}
            y={app.current ? app.current.scrollHeight : 1000}
          /> */}
        </div>
      </div>

      {mapLoad ? (
        <MiniMap handleMiniMap={handleMiniMap} miniMap={miniMap} />
      ) : null}

      <Shtor close={closeHandler} active={cuntain !== false || info} />
    </div>
  );
}

export default App;
